import {
  IdentityProvider,
  SocialFederationButton,
  SocialFederationButtonProps,
} from '@/components/atoms/legacy/SocialFederationButton/SocialFederationButton';
import EmailFederationButton from '../EmailFederationButton/EmailFederationButton';

const SUPPORTED_PROVIDERS: Readonly<IdentityProvider[]> = [
  'google',
  'apple',
  'microsoft',
  'facebook',
];

interface SocialFederationOptionsProps
  extends Pick<SocialFederationButtonProps, 'sendAnalytics' | 'actionType'> {
  handleSignInAction?: () => void;
}

export const SocialFederationOptions = ({
  handleSignInAction,
  sendAnalytics,
  actionType,
}: SocialFederationOptionsProps) => {
  return (
    <div className="flex w-full flex-col items-center gap-l">
      <div className="flex w-full flex-col gap-l">
        {SUPPORTED_PROVIDERS.map((provider) => (
          <SocialFederationButton
            key={provider}
            identityProvider={provider}
            actionType={actionType}
            preHook={handleSignInAction}
            sendAnalytics={sendAnalytics}
          />
        ))}
      </div>
      <div className="flex w-full items-center gap-l">
        <div className="h-px grow rounded-xsmall bg-neutral-200" />
        <div className="text-body2Regular text-neutral-800">OR</div>
        <div className="h-px grow rounded-xsmall bg-neutral-200" />
      </div>
      <EmailFederationButton
        actionType={actionType}
        preHook={handleSignInAction}
        sendAnalytics={sendAnalytics}
      />
    </div>
  );
};
