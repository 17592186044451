import Button, { ButtonProps } from '@/components/atoms/legacy/Button';
import { AuthDialog } from '@/components/organisms/AuthDialog/AuthDialog';
import { useAuth } from '@/hooks/useAuth';
import { HeartIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useCallback, useState } from 'react';

export interface HeartButtonProps
  extends Omit<
    ButtonProps,
    | 'children'
    | 'variant'
    | 'icon'
    | 'className'
    | 'withArrow'
    | 'analyticsEvent'
  > {
  onClick: (
    e?:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.TouchEvent<HTMLButtonElement>
  ) => Promise<void>;
  saved: boolean;
  size?: 'small' | 'medium';
  theme?: 'light' | 'dark' | 'transparent';
  isFlat?: boolean;
  /** A remote image URL of the vehicle to show in `AuthDialog` */
  authDialogImage: string;
  /** Optional action to be triggered before showing the `AuthDialog` if the user is signed out.. */
  onBeforeAuthDialog?: () => void;
}

export const HeartButton: React.FC<HeartButtonProps> = ({
  saved,
  size = 'medium',
  theme = 'light',
  loading,
  onClick,
  authDialogImage,
  isFlat = false,
  onBeforeAuthDialog,
  ...rest
}) => {
  let iconSize = size === 'small' ? 16 : 20;
  const buttonDimensions = size === 'small' ? 24 : 40;
  if (isFlat) {
    iconSize = size === 'small' ? 24 : 40;
  }
  let backgroundClassName = `hover:bg-white ${saved ? 'bg-white' : 'bg-white/80'}`;
  if (theme === 'dark') {
    backgroundClassName = 'bg-black hover:bg-neutral-800';
  } else if (theme === 'transparent') {
    backgroundClassName = 'bg-transparent';
  }
  const { user } = useAuth();

  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);

  const handleButtonAction = useCallback(
    async (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      e.stopPropagation();
      e.preventDefault();
      await onClick();
      if (!user) {
        if (onBeforeAuthDialog) {
          onBeforeAuthDialog();
        }
        setIsAuthDialogOpen(true);
      }
    },
    [onClick, user, onBeforeAuthDialog]
  );

  let iconClassName = 'transition-colors duration-200 ease-in-out stroke-[2px]';
  if (saved) {
    iconClassName += ' text-red-medium stroke-red-medium';
  } else if (theme === 'dark') {
    iconClassName +=
      ' text-black stroke-white group-hover:text-black group-hover:stroke-red-dark';
  } else {
    iconClassName +=
      ' text-white/20 stroke-neutral-800 group-hover:text-white group-hover:stroke-red-dark';
  }

  return (
    <>
      <Button
        {...rest}
        className={clsx(
          `flex h-[${buttonDimensions}px] w-[${buttonDimensions}px] flex-col items-center justify-center rounded-full border-[1px] ${backgroundClassName}`,
          {
            'px-xs py-[5px] shadow-m': !isFlat,
            'border-transparent': theme !== 'light',
            'border-neutral-0': theme === 'light',
          }
        )}
        disabled={loading}
        onTouchEnd={handleButtonAction}
        onClick={handleButtonAction}
        aria-label={saved ? 'Remove from saved' : 'Save to favorites'}
      >
        <HeartIcon
          width={iconSize}
          height={iconSize}
          className={iconClassName}
        />
      </Button>
      <AuthDialog
        isAuthDialogOpen={isAuthDialogOpen}
        backGroundImage={authDialogImage}
        setIsAuthDialogOpen={setIsAuthDialogOpen}
        renderIcon={
          <HeartIcon className="h-xl w-xl stroke-red-medium text-red-medium" />
        }
        title="Save Vehicle"
        description="Sign in or create an account to save your EVs and stay updated."
      />
    </>
  );
};
