import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

interface VehicleFilterSettings {
  isListedPrice: boolean;
  evSavings: boolean;
  displayIncentives: boolean;
  displayFuelSavings: boolean;
}
interface VehicleFilterSettingsContextValue {
  settings: VehicleFilterSettings;
  handleSetEvSavings: (value: boolean) => void;
  handleSetDisplayIncentives: (value: boolean) => void;
  handleSetDisplayFuelSavings: (value: boolean) => void;
  handleSetIsListedPrice: (value: boolean) => void;
}

type Action =
  | { type: 'SET_LISTED_PRICE'; payload: boolean }
  | { type: 'SET_EV_SAVINGS'; payload: boolean }
  | { type: 'SET_DISPLAY_INCENTIVES'; payload: boolean }
  | { type: 'SET_DISPLAY_FUEL_SAVINGS'; payload: boolean };

const initialState: VehicleFilterSettings = {
  isListedPrice: true,
  evSavings: true,
  displayIncentives: true,
  displayFuelSavings: true,
};

const settingsReducer = (
  state: VehicleFilterSettings,
  action: Action
): VehicleFilterSettings => {
  let newSettings: VehicleFilterSettings = initialState;
  switch (action.type) {
    case 'SET_LISTED_PRICE':
      newSettings = {
        ...state,
        isListedPrice: action.payload,
      };
      sessionStorage.setItem(
        'VehicleFilterSettings',
        JSON.stringify(newSettings)
      );
      return newSettings;

    case 'SET_EV_SAVINGS':
      newSettings = {
        ...state,
        evSavings: action.payload,
        displayIncentives: action.payload,
        displayFuelSavings: action.payload,
      };
      sessionStorage.setItem(
        'VehicleFilterSettings',
        JSON.stringify(newSettings)
      );
      return newSettings;
    case 'SET_DISPLAY_INCENTIVES':
      newSettings = { ...state, displayIncentives: action.payload };
      if (!newSettings.displayIncentives && !newSettings.displayFuelSavings) {
        newSettings.evSavings = false;
      }
      sessionStorage.setItem(
        'VehicleFilterSettings',
        JSON.stringify(newSettings)
      );
      return newSettings;
    case 'SET_DISPLAY_FUEL_SAVINGS':
      newSettings = { ...state, displayFuelSavings: action.payload };
      if (!newSettings.displayIncentives && !newSettings.displayFuelSavings) {
        newSettings.evSavings = false;
      }
      sessionStorage.setItem(
        'VehicleFilterSettings',
        JSON.stringify(newSettings)
      );
      return newSettings;
    default:
      return newSettings;
  }
};

const VehicleFilterSettingsContext =
  createContext<VehicleFilterSettingsContextValue | null>(null);

export const VehicleFilterSettingsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const getInitialState = useCallback(() => {
    if (typeof window !== 'undefined') {
      const sessionSettings = sessionStorage?.getItem('VehicleFilterSettings');
      if (sessionSettings != null) {
        return JSON.parse(sessionSettings);
      }
    }
    return initialState;
  }, []);

  const [settings, dispatch] = useReducer(settingsReducer, getInitialState());

  const handleSetEvSavings = useCallback((evSavings: boolean) => {
    dispatch({ type: 'SET_EV_SAVINGS', payload: evSavings });
  }, []);

  const handleSetDisplayIncentives = useCallback(
    (displayIncentives: boolean) => {
      if (settings.displayIncentives !== displayIncentives) {
        dispatch({
          type: 'SET_DISPLAY_INCENTIVES',
          payload: displayIncentives,
        });
      }
    },
    [settings.displayIncentives]
  );

  const handleSetDisplayFuelSavings = useCallback(
    (displayFuelSavings: boolean) => {
      if (settings.displayFuelSavings !== displayFuelSavings) {
        dispatch({
          type: 'SET_DISPLAY_FUEL_SAVINGS',
          payload: displayFuelSavings,
        });
      }
    },
    [settings.displayFuelSavings]
  );

  const handleSetIsListedPrice = useCallback(
    (isListedPrice: boolean) => {
      if (settings.isListedPrice !== isListedPrice) {
        dispatch({
          type: 'SET_LISTED_PRICE',
          payload: isListedPrice,
        });
      }
    },
    [settings.isListedPrice]
  );

  const contextValue = useMemo(() => {
    return {
      settings,
      handleSetEvSavings,
      handleSetDisplayIncentives,
      handleSetDisplayFuelSavings,
      handleSetIsListedPrice,
    };
  }, [
    settings,
    handleSetEvSavings,
    handleSetDisplayIncentives,
    handleSetDisplayFuelSavings,
    handleSetIsListedPrice,
  ]);

  return (
    <VehicleFilterSettingsContext.Provider value={contextValue}>
      {children}
    </VehicleFilterSettingsContext.Provider>
  );
};

export const useVehicleFilterSettingsContext = () => {
  const context = useContext(VehicleFilterSettingsContext);
  if (!context) {
    throw new Error(
      'useVehicleFilterSettingsContext must be used within a VehicleFilterSettingsProvider'
    );
  }
  return context;
};
