import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import { signInWithRedirect } from 'aws-amplify/auth';
import { FC } from 'react';
import { useAnalytics } from '@/components/molecules/AnalyticsProvider';

export type IdentityProvider = 'facebook' | 'google' | 'apple' | 'microsoft';

const ButtonConfigMap: Record<
  IdentityProvider,
  {
    name: string;
    signIn: () => Promise<void>;
    iconId: IconIds;
    analytics: Record<
      | 'login'
      | 'signup'
      | 'loginSuccess'
      | 'loginFail'
      | 'signupSuccess'
      | 'signupFail',
      AnalyticsEvent
    >;
    strokeColor?: string;
    fillColor?: string;
  }
> = {
  google: {
    iconId: IconIds.Google,
    analytics: {
      login: GOOGLE_TRACK_INFO.googleLoginButton,
      signup: GOOGLE_TRACK_INFO.googleSignUpButton,
      loginSuccess: GOOGLE_TRACK_INFO.googleLoginSuccess,
      loginFail: GOOGLE_TRACK_INFO.googleLoginFail,
      signupSuccess: GOOGLE_TRACK_INFO.googleSignupSuccess,
      signupFail: GOOGLE_TRACK_INFO.googleSignupFail,
    },
    signIn: async () => {
      await signInWithRedirect({ provider: 'Google' });
    },
    name: 'Google',
  },
  facebook: {
    iconId: IconIds.Facebook,
    analytics: {
      login: GOOGLE_TRACK_INFO.facebookLoginButton,
      signup: GOOGLE_TRACK_INFO.facebookSignUpButton,
      loginSuccess: GOOGLE_TRACK_INFO.facebookLoginSuccess,
      loginFail: GOOGLE_TRACK_INFO.facebookLoginFail,
      signupSuccess: GOOGLE_TRACK_INFO.facebookSignupSuccess,
      signupFail: GOOGLE_TRACK_INFO.facebookSignupFail,
    },
    signIn: async () => {
      await signInWithRedirect({ provider: 'Facebook' });
    },
    name: 'Facebook',
  },
  apple: {
    iconId: IconIds.BlackApple,
    analytics: {
      login: GOOGLE_TRACK_INFO.appleLoginButton,
      signup: GOOGLE_TRACK_INFO.appleSignUpButton,
      loginSuccess: GOOGLE_TRACK_INFO.appleLoginSuccess,
      loginFail: GOOGLE_TRACK_INFO.appleLoginFail,
      signupSuccess: GOOGLE_TRACK_INFO.appleSignupSuccess,
      signupFail: GOOGLE_TRACK_INFO.appleSignupFail,
    },
    signIn: async () => {
      await signInWithRedirect({ provider: 'Apple' });
    },
    name: 'Apple',
  },
  microsoft: {
    iconId: IconIds.Microsoft,
    analytics: {
      login: GOOGLE_TRACK_INFO.microsoftPersonalLoginButton,
      signup: GOOGLE_TRACK_INFO.microsoftPersonalSignUpButton,
      loginSuccess: GOOGLE_TRACK_INFO.microsoftLoginSuccess,
      loginFail: GOOGLE_TRACK_INFO.microsoftLoginFail,
      signupSuccess: GOOGLE_TRACK_INFO.microsoftSignupSuccess,
      signupFail: GOOGLE_TRACK_INFO.microsoftSignupFail,
    },
    signIn: async () => {
      await signInWithRedirect({ provider: { custom: 'Microsoft' } });
    },
    name: 'Microsoft',
  },
};

export interface SocialFederationButtonProps {
  identityProvider: IdentityProvider;
  actionType: 'login' | 'signup';
  sendAnalytics?: boolean;
  preHook?: () => void;
}

export const SocialFederationButton: FC<SocialFederationButtonProps> = ({
  identityProvider,
  actionType,
  sendAnalytics = true,
  preHook,
}) => {
  const {
    name,
    iconId,
    signIn,
    analytics,
    strokeColor: customStrokeColor,
    fillColor: customFillColor,
  } = ButtonConfigMap[identityProvider];
  const { handleActionTracking } = useAnalytics();

  const content = `Continue with ${name}`;

  return (
    <Button
      icon={
        iconId
          ? {
              icon: (
                <Icon
                  iconId={iconId}
                  className="size-xl"
                  fillColor={customFillColor}
                  strokeColor={customStrokeColor}
                />
              ),
              position: 'left',
            }
          : undefined
      }
      aria-label={content}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        preHook?.();
        signIn()
          .then(() => {
            const analyticsEvent =
              actionType === 'login'
                ? analytics.loginSuccess
                : analytics.signupSuccess;
            handleActionTracking(analyticsEvent);
          })
          .catch(() => {
            const analyticsEvent =
              actionType === 'login'
                ? analytics.loginFail
                : analytics.signupFail;
            handleActionTracking(analyticsEvent);
          });
      }}
      variant={ButtonVariants.SSO}
      analyticsEvent={sendAnalytics ? analytics[actionType] : undefined}
    >
      {content}
    </Button>
  );
};
